import Guard from './../services/auth'
export default [
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('@/views/usuarios/UsuariosPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Controle de Usuários',
          active: true,
        },
      ],
    },
  },
  {
    path: '/alterar-senha-usuario', name: 'alterar-senha-usuario',
    component: () => import('@/views/usuarios/AlterarSenha.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {pageTitle: 'Cadastros', breadcrumb: [{ text: 'Alterar Senha Usuário',active: true}]},
  },

  {
    path: '/alterar-foto-perfil', name: 'alterar-foto-perfil',
    component: () => import('@/views/usuarios/PerfilEditar.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {pageTitle: 'Usuário', breadcrumb: [{ text: 'Alterar Foto Perfil',active: true}]},
  },
]
