import Guard from './../services/auth'
export default [
  {
    path: '/tabela',
    name: 'tabela',
    component: () => import('@/views/tabela/TabelaPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: 'Tabela',
    },
  },
]