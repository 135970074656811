
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        pedidos: [],
        conveniados: [],
        imagens: [],
        entradasEtapas: []
    },
    mutations:{
        STORE_PEDIDOS(state, result) {
            state.pedidos = result
        },
        STORE_ETAPAS(state, result) {
            state.entradasEtapas = result
        },
        STORE_CONVENIADOS(state, result) {
            state.conveniados = result
        },
        UPDATE_PEDIDOS(state, result) {
            let index = state.pedidos.findIndex( v => v.id === result.id)
            state.pedidos.splice(index, 1, result)
        },
        DELETE_PEDIDO(state, result) {
            let index = state.pedidos.findIndex( v => v.id === result.id)
            state.pedidos.splice(index, 1)
        },

        // IMAGENS

        STORE_IMAGENS(state, result) {
            state.imagens = result
        },
        ADD_IMAGENS(state, result) {

            result.forEach( (v) => {
              if(!state.imagens.hasOwnProperty(v.os_ficha_id)) {
                Vue.set(state.imagens, v.os_ficha_id, [])
              }
                state.imagens[v.os_ficha_id].push(v)
            })
          },
        DELETE_IMAGENS(state, payload) {
            state.imagens[payload.id].splice(payload.index, 1)
        },
    },
    getters: {},
    actions:{
        listaPedidos(context, filters){
            axios(`odontodocs/pedidos/${filters.laboratorioSelecionado}?mes=${filters.mes}&ano=${filters.ano}&tipo=${filters.tipo}&etapas=${filters.etapas}`).then( (res) => {
                context.commit('STORE_PEDIDOS', res.data.entrada)
                context.commit('STORE_IMAGENS', res.data.imagens)
                context.commit('STORE_ETAPAS', res.data.etapas)
            })

        },
        listaConveniados(context, laboSelecionado){
            axios(`odontodocs/pedidos/conveniados/${laboSelecionado}`).then( (res) => {
                context.commit('STORE_CONVENIADOS', res.data)
            })

        },
    },

}
