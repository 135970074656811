export default {

    namespaced: true,

    state:{
        user:{
            acl: {}
        },
        errors_user: false,
    },

    mutations:{
        STORE_USER(state, user){
            state.user = user
        },
        STORE_USER_ERROR_LOGIN(state, error){
            state.errors_user = error
        },

        UPDATE_ACL(state, acl) {
            state.user.acl = acl
        }

    }

}
