
import Guard from './../services/auth'
export default [
  {
    path: '/pedidos',
    name: 'pedidos',
    component: () => import('@/views/pedidos/PedidosPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: 'Pedidos',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pedidos/novo/:id?',
    name: 'pedidos_novo',
    component: () => import('@/views/pedidos/LancarPedido.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: 'Pedidos',
      breadcrumb: [
        {
          text: 'Novo',
          active: true,
        },
      ],
    },
  },
]
