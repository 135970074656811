import Guard from './../services/auth'
export default [
  {
    path: '/financeiro',
    name: 'financeiro',
    component: () => import('@/views/financeiro/FinanceiroPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: 'Financeiro',
    },
  },
]