import Cookie from './../services/cookie'
import axios from './../libs/axios'
import store from './../store'

export default {

    redirectIfAuthenticated(to, from, next){

        const token = Cookie.getToken()
        let n;

        if(token){
            n = ({ name: 'home'})
        }

        next(n)

    },


  async redirectIfNotAuthenticated(to, from, next){

        const token = Cookie.getToken()
        const confirmation_token = Cookie.getLoginUniqueToken()

        if(!token || token === undefined){
            next( { name: 'login'})
        }else{
        await axios.get('odontodocs/me').then(res => {
            if(confirmation_token != res.data.data.confirmation_token){

              // Falta enviar uma Mensagem de Erro

              store.commit('user/STORE_USER_ERROR_LOGIN', true)
              next( { name: 'logout'})
            }else{

              if(!store?.state?.user?.user?.id){
                store.commit('user/STORE_USER', res.data.data)
              }
              // envia para tela do módulo de produção dos colaboradores
              // if(to.name !== 'controle-producao-colaboradores' && res.data.data.modulo_producao === 1 && res.data.data.owner === 0) {
              //   next( { name: 'controle-producao-colaboradores'})
              // }
            }

          }).catch(() => {
            Cookie.deleteToken()
            next( { name: 'login'})
          })
          next()
        }

    },



}



